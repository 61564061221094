var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c('section', {
    staticClass: "container",
    attrs: {
      "id": "schedule"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('h4', [_vm._v("Besichtigungtermine")]), _c('table', {
    staticClass: "scheduletable",
    class: {
      _smaller: _vm.usermetadata.leaveopen
    }
  }, [_c('thead', [_c('tr', {
    staticClass: "noselect"
  }, [_c('th', {
    on: {
      "click": function click($event) {
        _vm.mapsorter = "akt";
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Akt")]), _c('th', {
    on: {
      "click": function click($event) {
        _vm.mapsorter = "stid";
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Status")]), _c('th', {
    on: {
      "click": function click($event) {
        _vm.mapsorter = "besichtigungsdatum";
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Termin")]), _c('th', {
    on: {
      "click": function click($event) {
        _vm.mapsorter = "ba";
        _vm.reverse = !_vm.reverse;
      }
    }
  }, [_vm._v("Bearbeiter")]), _c('th', [_vm._v("Addresse")]), _c('th', [_vm._v("Link")])])]), _c('tbody', _vm._l(_vm.mapfilteredlist, function (it) {
    return _c('tr', [_c('td', [_c('router-link', {
      attrs: {
        "to": "/crmakt/".concat(it.aktname)
      }
    }, [_c('span', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(it.art) + " " + _vm._s(it.akt))]), it.bezeichnung ? _c('small', {
      staticClass: "color-blue"
    }, [_c('br'), _vm._v(_vm._s(it.bezeichnung.substring(0, 16)) + "...")]) : _vm._e()])], 1), _c('td', [_c('div', {
      staticClass: "tac"
    }, [_vm._v(_vm._s(_vm.status[it.stid].substring(0, 2)))])]), _c('td', [_vm._v(_vm._s(it.dateDisplay))]), _c('td', [_vm._v(_vm._s(it.ba))]), _c('td', [_vm._v(_vm._s(it.besichtigungsadresse))]), _c('td', [_c('a', {
      attrs: {
        "target": "blank",
        "href": 'https://consense.crm4.dynamics.com/main.aspx?etc=10009&id=' + it.aid + '&Pagetype=entityrecord'
      }
    }, [_vm._v("CRM Link")])])]);
  }), 0)])]), _c('FilterSideBar', {
    attrs: {
      "filtercounts": _vm.mapfilteredlist.length
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };